import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../api/api";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import { userToken } from "../../util/userToken";
import { IObjectVerwaltung } from "./objectVerwaltung";

export interface IUser {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  postCode: string;
  email: string;
  errorMsg: string;
}

export interface IObjectIdUser {
  abrechnungsartnr: number;
  abstimmkonto: string;
  anrede: string;
  branchekennung: string;
  debitornr: number;
  email?: string | null;
  fremdschluesselkennung: string;
  gpnr?: string | null | number;
  grosskunde_jn?: number | null;
  hausnr: string;
  kontengruppe: string;
  la: number;
  lagehinweis?: null | string;
  land: string;
  mahnverfahrenkennung: string;
  nachname: string;
  namezusatz: null | string;
  ort: string;
  partnergesellschaft: null | string;
  plz: string;
  plzpostfach: null | string;
  postfach: null | string;
  sachbearbeiter1kennung: string;
  sachbearbeiter2kennung: string;
  strasse: string;
  telefax: null | string;
  telefon: null | string;
  titel: null | string;
  va: number;
  vorname: string;
  zahlungsartkennung: string;
  zahlungsbedingungnr: number;
}

export interface IObjectUserResponse {
  user: IObjectIdUser;
  token: string;
  defaultAdress: IObjectVerwaltung;
}

interface IInitState {
  user: IUser;
  objectIdUser: IObjectIdUser;
  defaultUserObject: IObjectVerwaltung | null;
  isUserDataLoading: boolean;
}

const initialState: IInitState = {
  objectIdUser: {
    abrechnungsartnr: 0,
    abstimmkonto: "",
    anrede: "",
    branchekennung: "",
    debitornr: 0,
    fremdschluesselkennung: "",
    hausnr: "",
    kontengruppe: "",
    la: 0,
    land: "",
    mahnverfahrenkennung: "",
    nachname: "",
    namezusatz: "",
    ort: "",
    partnergesellschaft: "",
    plz: "",
    plzpostfach: "",
    postfach: "",
    sachbearbeiter1kennung: "",
    sachbearbeiter2kennung: "",
    strasse: "",
    telefax: "",
    telefon: "",
    titel: "",
    va: 0,
    vorname: "",
    zahlungsartkennung: "",
    zahlungsbedingungnr: 0,
    email: "",
    gpnr: "",
    grosskunde_jn: 0,
    lagehinweis: "",
  },
  defaultUserObject: {
    adressart: "",
    hausnr: 0,
    hausnrzusatz: null,
    kassenzeichen: 0,
    objektid: 0,
    strassebez: "",
    plz: "",
    bezirkbez: null,
    bezirknr: null,
    gemeindebez: "",
    gemeindenr: 0,
    hausnrinfo: "",
    ortsteilbez: "",
    ortsteilnr: 0,
    strassenr: 0,
  },
  user: {
    city: "",
    email: "",
    errorMsg: "",
    firstName: "",
    lastName: "",
    postCode: "",
    street: "",
  },
  isUserDataLoading: false,
  // firstName: "",
  // lastName: "",
  // city: "",
  // street: "",
  // postCode: "",
  // email: "",
  // errorMsg: "",
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (code: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = await API.auth.post.nrwLogin(code);
      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const objectIdLogin = createAsyncThunk(
  "user/objectIdLogin",
  async ({ adrId }: { adrId: string; navigate: () => void }, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.post.objectIdLogin({ adrId });
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const viersenLogin = createAsyncThunk(
  "user/viersenLogin",
  async (
    {
      login,
      password,
    }: { login: string; password: string; navigate: () => void },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.post.loginViersen({ login, password });
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getMe = createAsyncThunk(
  "user/getMe",
  async (token: string, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.post.me(token);

      return data;
    } catch (error: any) {
      dispatch(clearObjectIdUser());
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getMeInfo = createAsyncThunk(
  "user/getMeInfo",
  async (token: string, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.get.meInfo(token);

      return data;
    } catch (error: any) {
      dispatch(clearObjectIdUser());
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const meInfoCheck = createAsyncThunk(
  "user/meInfoCheck",
  async (token: string, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.get.meInfoCheck(token);

      return data;
    } catch (error: any) {
      dispatch(clearObjectIdUser());
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearObjectIdUser: (state) => {
      state.objectIdUser = initialState.objectIdUser;
    },
    logout: (state, action: PayloadAction<{ navigate: () => void }>) => {
      state.objectIdUser = initialState.objectIdUser;
      state.defaultUserObject = initialState.defaultUserObject;
      userToken.remove();
      action.payload.navigate();
    },
    setDefaultUserObject: (
      state,
      action: PayloadAction<{ item: IObjectVerwaltung; navigate: () => void }>
    ) => {
      state.defaultUserObject = action.payload.item;
      action.payload.navigate();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.message && action.payload.message.length) {
        state.user.errorMsg = action.payload.message;
      } else {
        state.user.street = action.payload?.street!;
        state.user.city = action.payload?.city!;
        state.user.email = action.payload?.email!;
        state.user.firstName = action.payload?.firstName!;
        state.user.lastName = action.payload?.lastName!;
        state.user.postCode = action.payload?.postCode!;
      }
    });
    builder.addCase(objectIdLogin.pending, (state) => {
      state.isUserDataLoading = true;
    });
    builder.addCase(objectIdLogin.fulfilled, (state, action) => {
      if (action.payload) {
        userToken.set(action.payload.token);
        const phone = action?.payload?.user?.telefon?.toString().startsWith("0")
          ? action?.payload?.user?.telefon?.toString().replaceAll("0", "49")
          : action?.payload?.user?.telefon;
        state.objectIdUser = {
          ...action.payload.user,
          telefon: phone,
        };
        if (action.payload.defaultAdress) {
          state.defaultUserObject = action.payload.defaultAdress;
        } else {
          state.defaultUserObject = null;
        }
        action.meta.arg.navigate();
      }
      state.isUserDataLoading = false;
    });
    builder.addCase(objectIdLogin.rejected, (state) => {
      state.isUserDataLoading = false;
    });
    builder.addCase(viersenLogin.pending, (state) => {
      state.isUserDataLoading = true;
    });
    builder.addCase(viersenLogin.fulfilled, (state, action) => {
      if (action.payload) {
        userToken.set(action.payload.token);
        const phone = action?.payload?.user?.telefon?.toString().startsWith("0")
          ? action?.payload?.user?.telefon?.toString().replaceAll("0", "49")
          : action?.payload?.user?.telefon;
        state.objectIdUser = {
          ...action.payload.user,
          telefon: phone,
        };
        if (action.payload.defaultAdress) {
          state.defaultUserObject = action.payload.defaultAdress;
        } else {
          state.defaultUserObject = null;
        }
        action.meta.arg.navigate();
      }
      state.isUserDataLoading = false;
    });
    builder.addCase(viersenLogin.rejected, (state) => {
      state.isUserDataLoading = false;
    });
    builder.addCase(getMe.pending, (state) => {
      state.isUserDataLoading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      if (action.payload) {
        console.log('ac', action.payload)
        const phone = action?.payload?.user?.telefon?.toString().startsWith("0")
          ? action?.payload?.user?.telefon?.toString().replaceAll("0", "49")
          : action?.payload?.user?.telefon;
        userToken.set(action.payload.token);
        state.objectIdUser = {
          ...action.payload.user,
          telefon: phone,
        };
        state.defaultUserObject = action.payload.defaultAdress;
      }
      state.isUserDataLoading = false;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.isUserDataLoading = false;
    });
    builder.addCase(getMeInfo.pending, (state) => {
      state.isUserDataLoading = true;
    });
    builder.addCase(getMeInfo.fulfilled, (state, action) => {
      if (action.payload) {
        const phone = action?.payload?.user?.telefon?.toString().startsWith("0")
          ? action?.payload?.user?.telefon?.toString().replaceAll("0", "49")
          : action?.payload?.user?.telefon;
        userToken.set(action.payload.token);
        state.objectIdUser = {
          ...action.payload.user,
          telefon: phone,
        };
        state.defaultUserObject = action.payload.defaultAdress;
      }
      state.isUserDataLoading = false;
    });
    builder.addCase(getMeInfo.rejected, (state) => {
      state.isUserDataLoading = false;
    });
    builder.addCase(meInfoCheck.pending, (state) => {
      state.isUserDataLoading = true;
    });
    builder.addCase(meInfoCheck.fulfilled, (state, action) => {
      if (action.payload) {
        const phone = action?.payload?.user?.telefon?.toString().startsWith("0")
          ? action?.payload?.user?.telefon?.toString().replaceAll("0", "49")
          : action?.payload?.user?.telefon;
        userToken.set(action.payload.token);
        state.objectIdUser = {
          ...action.payload.user,
          telefon: phone,
        };
        state.defaultUserObject = action.payload.defaultAdress;
      }
      state.isUserDataLoading = false;
    });
    builder.addCase(meInfoCheck.rejected, (state) => {
      state.isUserDataLoading = false;
    });
  },
});

export const { clearObjectIdUser, logout, setDefaultUserObject } =
  userSlice.actions;

export const userReducer = userSlice.reducer;
