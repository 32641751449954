import React, { FC } from "react";
import style from "./style.module.scss";

interface IAdminDetailInfoItem {
  title: string;
  items?: {
    rowTitle?: string;
    rowInfo?: string;
    rowCustomItem?: any;
    isLast?: boolean;
  }[];
}

export const AdminDetailInfoItem: FC<IAdminDetailInfoItem> = ({
  items,
  title,
}) => {
  return (
    <div>
      <div className={style.title}>{title}</div>
      <div className={style.tableInfoWrapper}>
        {items?.map((el, idx) => {
          if (!Object.keys(el).length) {
            return null;
          }
          const isLast = items?.length === idx + 1;
          return (
            <div
              key={idx}
              className={isLast ? style.lastRowWrapper : style.rowWrapper}
            >
              <div className={style.rowTitle}>{el.rowTitle}</div>
              <div className={style.rowInfo}>
                {el.rowInfo}
                {el.rowCustomItem}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
