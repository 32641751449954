import { IObjectUserResponse } from "../../store/slices/user";
import appConfig from "../../util/appConfig";
import { userToken } from "../../util/userToken";
import { instance, userInstance } from "../api";

interface IUser {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  postCode: string;
  email: string;
}

const createUser = (email: string, password: string) => {
  return instance
    .post("/registration", {
      email,
      password,
    })
    .then((res) => res.data);
};

const login = (email: string, password: string) => {
  return instance
    .post<{ token: string }>("/login", {
      email,
      password,
    })
    .then((res) => res.data);
};

const logout = () => {
  return instance.delete("/logout").then(() => {
    userToken.remove();
  });
};

const nrwLogin = (code: string) => {
  return instance
    .post<IUser | { message: string }>("/login/nrw", { code })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const loginViersen = ({
  login,
  password,
}: {
  login: string;
  password: string;
}) => {
  // return instance
  //   .post<{ token: string }>("/login/viersen", {
  //     login,
  //     password,
  //   })
  //   .then((res) => res.data);
  //TODO: remove this afterwards
  if (appConfig.logo === "viersen") {
    return new Promise((res) => {
      res({
        defaultAdress: {
          adressart: "Eigentümer",
          bezirkbez: null,
          bezirknr: null,
          gemeindebez: "Adelzhausen",
          gemeindenr: 1111,
          hausnr: 999,
          hausnrinfo: "999",
          hausnrzusatz: null,
          kassenzeichen: 2,
          objektid: 56388,
          ortsteilbez: "Testdorf",
          ortsteilnr: 7,
          plz: "86559",
          strassebez: "Neue Teststr.",
          strassenr: 116,
        },
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZHJJZCI6IjUyMzA1IiwibmFtZSI6Ik1pY2hhZWwiLCJzdXJuYW1lIjoiVGhpc3NlbiIsInBob25lTnVtYmVyIjpudWxsLCJpYXQiOjE3MzAxMTEyNTMsImV4cCI6MTczMDI4NDA1M30.aiDlOgsyd1slJt0Wg3J50yrSVu5f1cIdyH4F7pMQFjw",
        user: {
          abrechnungsartnr: 1,
          abstimmkonto: "40000",
          anrede: "HR",
          branchekennung: "9999",
          debitornr: 152208,
          email: null,
          fremdschluesselkennung: null,
          gpnr: null,
          grosskunde_jn: 0,
          hausnr: "1000",
          kontengruppe: "DEB",
          la: 0,
          lagehinweis: null,
          land: "D",
          mahnverfahrenkennung: "01",
          nachname: "Thissen",
          namezusatz: null,
          ort: "Test Stadt",
          partnergesellschaft: null,
          plz: "86551",
          plzpostfach: null,
          postfach: null,
          sachbearbeiter1kennung: "1",
          sachbearbeiter2kennung: "1",
          strasse: "Test Straße",
          telefax: null,
          telefon: null,
          titel: null,
          va: 1,
          vorname: "Michael",
          zahlungsartkennung: "U",
          zahlungsbedingungnr: 30,
        },
      });
    }) as any;
  }
  return instance
    .post<IObjectUserResponse>("login/object-id", { adrId: login })
    .then((res) => res.data);
};

const objectIdLogin = (data: { adrId: string }) => {
  return instance
    .post<IObjectUserResponse>("login/object-id", data)
    .then((res) => res.data);
};
const me = (token: string) => {
  if (appConfig.logo === "viersen") {
    return new Promise((res) => {
      res({
        defaultAdress: {
          adressart: "Eigentümer",
          bezirkbez: null,
          bezirknr: null,
          gemeindebez: "Adelzhausen",
          gemeindenr: 1111,
          hausnr: 999,
          hausnrinfo: "999",
          hausnrzusatz: null,
          kassenzeichen: 2,
          objektid: 56388,
          ortsteilbez: "Testdorf",
          ortsteilnr: 7,
          plz: "86559",
          strassebez: "Neue Teststr.",
          strassenr: 116,
        },
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZHJJZCI6IjUyMzA1IiwibmFtZSI6Ik1pY2hhZWwiLCJzdXJuYW1lIjoiVGhpc3NlbiIsInBob25lTnVtYmVyIjpudWxsLCJpYXQiOjE3MzAxMTEyNTMsImV4cCI6MTczMDI4NDA1M30.aiDlOgsyd1slJt0Wg3J50yrSVu5f1cIdyH4F7pMQFjw",
        user: {
          abrechnungsartnr: 1,
          abstimmkonto: "40000",
          anrede: "HR",
          branchekennung: "9999",
          debitornr: 152208,
          email: null,
          fremdschluesselkennung: null,
          gpnr: null,
          grosskunde_jn: 0,
          hausnr: "1000",
          kontengruppe: "DEB",
          la: 0,
          lagehinweis: null,
          land: "D",
          mahnverfahrenkennung: "01",
          nachname: "Thissen",
          namezusatz: null,
          ort: "Test Stadt",
          partnergesellschaft: null,
          plz: "86551",
          plzpostfach: null,
          postfach: null,
          sachbearbeiter1kennung: "1",
          sachbearbeiter2kennung: "1",
          strasse: "Test Straße",
          telefax: null,
          telefon: null,
          titel: null,
          va: 1,
          vorname: "Michael",
          zahlungsartkennung: "U",
          zahlungsbedingungnr: 30,
        },
      });
    }) as any;
  }
  return userInstance
    .get<IObjectUserResponse>(`login/me?token=${token}`)
    .then((res) => res.data);
};
const meInfo = (token: string) => {
  return userInstance
    .get<IObjectUserResponse>(`login/me-info?token=${token}`)
    .then((res) => res.data);
};

const meInfoCheck = (token: string) => {
  return userInstance
    .get<IObjectUserResponse>(`login/me-info/check`)
    .then((res) => res.data);
};

const Auth = {
  get: {
    meInfo,
    meInfoCheck,
  },
  post: {
    createUser,
    login,
    nrwLogin,
    loginViersen,
    objectIdLogin,
    me,
  },
  delete: {
    logout,
  },
};

export default Auth;
