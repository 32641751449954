import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import style from "./style.module.scss";
import { BtnLink } from "../BtnLink";
import appConfig from "../../util/appConfig";
import { useAppDispatch, useAppSelector } from "../../store";
import awlLogo from "../../assets/images/logos/awlLogoNew.png";
import bkLogo from "../../assets/images/logos/bkLogo.svg";
import sensisLogo from "../../assets/images/logos/sensisLogo.svg";
import ebwoLogo from "../../assets/images/logos/ebwoLogo.jpg";
import lraLogo from "../../assets/images/logos/lraLogo.svg";
import lraMobileLogo from "../../assets/images/logos/lraMobileLogo.svg";
import calwLogo from "../../assets/images/logos/calwLogo.svg";
import { setIsBurgerMenu } from "../../store/slices/app";
import { HeaderSelect } from "../HeaderSelect";
import { SmallLoader } from "../Loader";

export const Header = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { isLoading, isBurgerMenuOpen, isMobile } = useAppSelector(
    (state) => state.app.client
  );
  const {
    objectIdUser: { nachname },
    isUserDataLoading,
  } = useAppSelector((state) => state.user);

  const handleBurgerMenu = () => {
    dispatch(setIsBurgerMenu(!isBurgerMenuOpen));
  };

  const logo = (logo: string) => {
    switch (logo) {
      case "awl": {
        return awlLogo;
      }
      case "bk": {
        return bkLogo;
      }
      case "sensis": {
        return sensisLogo;
      }
      case "ebwo": {
        return ebwoLogo;
      }
      case "lra": {
        return isMobile ? lraMobileLogo : lraLogo;
      }
      case "calw": {
        return calwLogo;
      }
      default: {
        return sensisLogo;
      }
    }
  };

  useEffect(() => {
    const header = document.querySelector<HTMLElement>("#header");
    const platform = window.navigator.platform;
    if (isLoading || isBurgerMenuOpen) {
      document.body.style.overflow = "hidden";
      if (header && platform.includes("Win")) {
        header.style.paddingRight = "17px";
      }
    }

    if (!isLoading && !isBurgerMenuOpen) {
      document.body.style.overflow = "inherit";
      if (header && platform.includes("Win")) {
        header.style.paddingRight = "0";
      }
    }
  }, [isBurgerMenuOpen, isLoading]);

  if (["viersen"].includes(appConfig.logo) && location.pathname === "/login") {
    return null;
  }

  const generateLink = (type: string) => {
    switch (type) {
      case "viersen": {
        return "/sepa-management";
      }
      default: {
        return "/";
      }
    }
  };

  return (
    <div
      className={style.header}
      id="header"
      style={{
        filter: isLoading ? "blur(2px)" : "",
      }}
    >
      <div className={style.header_container}>
        <nav className={style.nav_container}>
          <div>
            <Link
              to={generateLink(appConfig.logo)}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                })
              }
            >
              <img
                src={logo(appConfig.logo)}
                alt={appConfig.logo}
                style={{
                  maxWidth: appConfig.logo === "awl" ? "74px" : "",
                }}
                className={appConfig.logo === "ebwo" ? style.ebwoLogo : ""}
              />
            </Link>
          </div>
          {appConfig.logo === "sensis" && (
            <div className={style.auth_links_container}>
              <div className={style.login_link}>
                <a href={appConfig.nrwLoginURl}>Anmelden</a>
              </div>
              <BtnLink
                isHashLink={true}
                link={appConfig.nrwSignupUrl}
                title="Registrieren"
              />
            </div>
          )}
          {[appConfig.envName === "dev" ? "lra" : "", "calw"].includes(
            appConfig.logo
          ) && !isMobile ? (
            isUserDataLoading ? (
              <SmallLoader loaderDotSize="7" />
            ) : !nachname ? (
              <Link
                to={"/login"}
                className={`${style.portal_link} ${style.main_link} ${style.anmelden}`}
              >
                Anmelden
              </Link>
            ) : (
              // <div>
              //   <Link to={"/kassenzeichen-verwaltung"} className={style.anmelden}>
              //     {vorname}, {nachname}
              //   </Link>
              //   <div
              //     className={style.anmelden}
              //     style={{
              //       fontSize: "8px",
              //       marginTop: '5px'
              //     }}
              //   >
              //     {strasse}, {hausnr}
              //   </div>
              //   <div
              //     className={style.anmelden}
              //     style={{
              //       fontSize: "8px",
              //     }}
              //   >
              //     {plz} {ort}
              //   </div>
              // </div>
              <HeaderSelect />
            )
          ) : null}
        </nav>
        {["viersen"].includes(appConfig.logo) ? null : (
          <nav
            className={
              ["sensis", "lra", "calw"].includes(appConfig.logo)
                ? style.nav_absolute_container
                : style.nav_absolute_container2
            }
          >
            <div className={`${style.portal_link} ${style.main_link}`}>
              <Link to="/">PORTAL</Link>
            </div>
            {!["lra"].includes(appConfig.logo) ? (
              <div
                className={`${style.main_link} ${
                  appConfig.logo === "sensis" && style.faq_link
                }`}
              >
                <a href="/#faq">FAQS</a>
              </div>
            ) : null}
            {appConfig.logo === "sensis" ? (
              <div className={`${style.main_link}`}>
                <Link to="/actual">AKTUELLES</Link>
              </div>
            ) : null}
          </nav>
        )}
        {isMobile ? (
          <div className={style.burgerContainer} onClick={handleBurgerMenu}>
            <div className={style.burgerWrapper}>
              <div
                className={`${style.middle} ${
                  isBurgerMenuOpen ? style.isMiddleOpened : ""
                }`}
              ></div>
              <div
                className={`${style.long} ${
                  isBurgerMenuOpen ? style.isLongOpened : ""
                }`}
              ></div>
              <div
                className={`${style.short} ${
                  isBurgerMenuOpen ? style.isShortOpened : ""
                }`}
              ></div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
